import { createAction, props } from '@ngrx/store';
import { AccountInfoModel } from 'app/models/api/account-info-model';
import { AccountModel } from 'app/models/api/account-model';
import { AuthorizedAccountModel } from 'app/models/api/authorized-account-model';
import { CodeVerificationModel } from 'app/models/api/code-verification-model';
import { CurrentUserPasswordResetVerifyCodeModel } from 'app/models/api/current-user-password-reset-verify-code-model';
import { FileGeneratedNotification } from 'app/models/api/file-generated-notification';
import { ForgotPasswordRequest } from 'app/models/api/forgot-password-request';
import { GeneratedFileModel } from 'app/models/api/generated-file-model';
import { MfaChallengeModel } from 'app/models/api/mfa-challenge-model';
import { NotificationsModel } from 'app/models/api/notifications-model';
import { PasswordResetConfirmationModel } from 'app/models/api/password-reset-confirmation-model';
import { PermissionModel } from 'app/models/api/permission-model';
import { SendPasswordResetVerifyCodeModel } from 'app/models/api/send-password-reset-verify-code-model';
import { SignInModel } from 'app/models/api/sign-in-model';
import { UpdateAccountModel } from 'app/models/api/update-account-model';
import { PreviousRoute } from 'app/models/dto/previous-route';
import { TokenRequest } from 'app/models/api/token-request';
import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';


export enum AccountActions {
	SetRedirectUrl = '[Account] SetRedirectUrl',
	SetTokens = '[Account] SetTokens',
	SetPermissions = '[Account] SetPermissions',

	SignIn = '[Account] SignIn',
	ResendSignIn = '[Account] ResendSignIn',
	SignInFailed = '[Account] SignInFailed',
	MfaVerificationStarted = '[Account] MfaVerificationStarted',
	VerifyCode = '[Account] VerifyCode',
	CodeVerified = '[Account] CodeVerified',
	VerificationFailed = '[Account] VerificationFailed',

	GetToken = '[Account] GetToken',
	TokenUploaded = '[Account] TokenUploaded',

	CheckUsersPasswordResetRequired = '[Account] CheckUsersPasswordResetRequired',
	UsersPasswordResetRequiredChecked = '[Account] UsersPasswordResetRequiredChecked',

	RequirePasswordReset = '[Account] RequirePasswordReset',

	AuthorizationSucceeded = '[Account] AuthenticationSucceeded',
	SignOut = '[Account] SignOut',

	LoadCurrentUserInfo = '[Account] LoadCurrentUserInfo',
	CurrentUserInfoLoaded = '[Account] CurrentUserInfoLoaded',

	LoadCurrentUser = '[Account] LoadCurrentUser',
	CurrentUserLoaded = '[Account] CurrentUserLoaded',

	LoadCurrentUserGeneratedFile = '[Account] LoadCurrentUserGeneratedFile',
	CurrentUserGeneratedFileLoaded = '[Account] CurrentUserGeneratedFileLoaded',

	DownloadGeneratedFile = '[Account] DownloadGeneratedFile',
	GeneratedFileDownloaded = '[Account] GeneratedFileDownloaded',

	DeleteGeneratedFile = '[Account] DeleteGeneratedFile',
	GeneratedFileDeleted = '[Account] GeneratedFileDeleted',

	GeneratedFileStatusUpdated = '[Account] GeneratedFileStatusUpdated',

	LoadCurrentUserNotifications = '[Account] LoadCurrentUserNotifications',
	CurrentUserNotificationLoaded = '[Account] CurrentUserNotificationLoaded',

	SaveCurrentUserInfo = '[Account] SaveCurrentUserInfo',
	CurrentUserInfoSaved = '[Account] CurrentUserInfoSaved',

	SendForgotPasswordRequest = '[Account] ForgotPasswordRequest',
	ForgotPasswordRequestSent = '[Account] ForgotPasswordRequestSent',
	SendPasswordResetVerifyCode = '[Account] SendPasswordResetVerifyCode',
	PasswordResetVerifyCodeSent = '[Account] PasswordResetVerifyCodeSent',
	ConfirmPasswordReset = '[Account] ConfirmedPasswordReset',
	PasswordResetConfirmed = '[Account] PasswordResetConfirmed',

	ResetCurrentUserPasswordRequest = '[Account] ResetCurrentUserPassword',
	CurrentUserPasswordReseted = '[Account] CurrentUserPasswordReseted',

	ResetUserPasswordRequest = '[Account] ResetUserPassword',
	UserPasswordReseted = '[Account] UserPasswordReseted',

	SendAuthenticatedUserPasswordResetVerifyCode = '[Account] SendCurrentUserPasswordResetVerifyCode',
	AuthenticatedUserPasswordResetVerifyCodeSent = '[Account] AuthenticatedUserPasswordResetVerifyCodeSent',

	RefreshToken = '[Account] RefreshToken',
	RefreshTokenSucceeded = '[Account] RefreshTokenSucceeded',
	RefreshTokenFailed = '[Account] RefreshTokenFailed',

	UpdatePreviousRoute = '[Account] UpdatePreviousRoute',

	SubscribeToGroups = '[Account] [Event] SubscribeToGroup',
	UnsubscribeFromGroups = '[Account] [Event] UnSubscribeFromGroup',

	ErrorOccurred = '[Account] ErrorOccurred'
}

export const setRedirectUrl = createAction(
	AccountActions.SetRedirectUrl,
	props<{ redirectUrl: string }>()
);

export const setTokens = createAction(
	AccountActions.SetTokens,
	props<{ token: string; refreshToken: string }>()
);

export const setPermissions = createAction(
	AccountActions.SetPermissions,
	props<{ permissions: PermissionModel[] }>()
);

export const signIn = createAction(
	AccountActions.SignIn,
	props<
		ActionRequestPayload<{ signInModel: SignInModel; rememberMe: boolean }>
	>()
);

export const resendSignIn = createAction(
	AccountActions.ResendSignIn,
	props<ActionRequestPayload<TokenRequest>>()
);

export const signInFailed = createAction(AccountActions.SignInFailed);

export const mfaVerificationStarted = createAction(
	AccountActions.MfaVerificationStarted,
	props<ActionResponsePayload<MfaChallengeModel>>()
);

export const verificationStarted = createAction(
	AccountActions.MfaVerificationStarted,
	props<ActionResponsePayload<MfaChallengeModel>>()
);

export const verifyCode = createAction(
	AccountActions.VerifyCode,
	props<ActionRequestPayload<CodeVerificationModel>>()
);

export const codeVerified = createAction(
	AccountActions.CodeVerified,
	props<ActionResponsePayload<AuthorizedAccountModel>>()
);

export const verificationFailed = createAction(
	AccountActions.VerificationFailed
);

export const getToken = createAction(
	AccountActions.GetToken,
	props<ActionRequestPayload<string>>()
);

export const tokenUploaded = createAction(
	AccountActions.TokenUploaded,
	props<ActionResponsePayload<AuthorizedAccountModel>>()
);

export const checkUsersPasswordResetRequired = createAction(
	AccountActions.CheckUsersPasswordResetRequired,
	props<ActionResponsePayload<MfaChallengeModel>>()
);

export const usersPasswordResetRequiredChecked = createAction(
	AccountActions.UsersPasswordResetRequiredChecked,
	props<ActionResponsePayload<boolean>>()
);

export const requirePasswordReset = createAction(
	AccountActions.RequirePasswordReset
);

export const authenticationSucceeded = createAction(
	AccountActions.AuthorizationSucceeded
);

export const sendForgotPasswordRequest = createAction(
	AccountActions.SendForgotPasswordRequest,
	props<ActionRequestPayload<ForgotPasswordRequest>>()
);

export const forgotPasswordRequestSent = createAction(
	AccountActions.SendForgotPasswordRequest
);

export const sendPasswordResetVerifyCode = createAction(
	AccountActions.SendPasswordResetVerifyCode,
	props<ActionRequestPayload<SendPasswordResetVerifyCodeModel>>()
);

export const passwordResetVerifyCodeSent = createAction(
	AccountActions.PasswordResetVerifyCodeSent
);

export const confirmPasswordReset = createAction(
	AccountActions.ConfirmPasswordReset,
	props<ActionRequestPayload<PasswordResetConfirmationModel>>()
);

export const passwordResetConfirmed = createAction(
	AccountActions.PasswordResetConfirmed
);

export const sendAuthenticatedUserPasswordResetVerifyCode = createAction(
	AccountActions.SendAuthenticatedUserPasswordResetVerifyCode,
	props<ActionRequestPayload<CurrentUserPasswordResetVerifyCodeModel>>()
);

export const authenticatedUserPasswordResetVerifyCodeSent = createAction(
	AccountActions.AuthenticatedUserPasswordResetVerifyCodeSent
);

export const signOut = createAction(AccountActions.SignOut);

export const loadCurrentUserGeneratedFile = createAction(
	AccountActions.LoadCurrentUserGeneratedFile,
	props<ActionRequestPayload<void>>()
);

export const currentUserGeneratedFileLoaded = createAction(
	AccountActions.CurrentUserGeneratedFileLoaded,
	props<ActionResponsePayload<GeneratedFileModel>>()
);

export const downloadGeneratedFile = createAction(
	AccountActions.DownloadGeneratedFile,
	props<ActionRequestPayload<number>>()
);

export const generatedFileDownloaded = createAction(
	AccountActions.GeneratedFileDownloaded
);

export const deleteGeneratedFile = createAction(
	AccountActions.DeleteGeneratedFile,
	props<ActionRequestPayload<number>>()
);

export const generatedFileDeleted = createAction(
	AccountActions.GeneratedFileDeleted
);

export const generatedFileStatusUpdated = createAction(
	AccountActions.GeneratedFileStatusUpdated,
	props<ActionResponsePayload<FileGeneratedNotification>>()
);

export const loadCurrentUserNotifications = createAction(
	AccountActions.LoadCurrentUserNotifications,
	props<ActionRequestPayload<void>>()
);

export const currentUserNotificationLoaded = createAction(
	AccountActions.CurrentUserNotificationLoaded,
	props<ActionResponsePayload<NotificationsModel>>()
);

export const loadCurrentUser = createAction(
	AccountActions.LoadCurrentUser,
	props<ActionRequestPayload<void>>()
);

export const currentUserLoaded = createAction(
	AccountActions.CurrentUserLoaded,
	props<ActionResponsePayload<AccountModel>>()
);

export const loadCurrentUserInfo = createAction(
	AccountActions.LoadCurrentUserInfo,
	props<ActionRequestPayload<void>>()
);

export const currentUserInfoLoaded = createAction(
	AccountActions.CurrentUserInfoLoaded,
	props<ActionResponsePayload<AccountInfoModel>>()
);

export const saveCurrentUserInfo = createAction(
	AccountActions.SaveCurrentUserInfo,
	props<ActionRequestPayload<UpdateAccountModel>>()
);

export const currentUserInfoSaved = createAction(
	AccountActions.CurrentUserInfoSaved,
	props<ActionResponsePayload<UpdateAccountModel>>()
);

export const refreshToken = createAction(AccountActions.RefreshToken);

export const refreshTokenSucceeded = createAction(
	AccountActions.RefreshTokenSucceeded,
	props<ActionResponsePayload<AuthorizedAccountModel>>()
);

export const refreshTokenFailed = createAction(
	AccountActions.RefreshTokenFailed
);

export const updatePreviousRoute = createAction(
	AccountActions.UpdatePreviousRoute,
	props<PreviousRoute>()
);

//for current user pwd reset
export const sendCurrentUserChangePasswordRequest = createAction(
	AccountActions.ResetCurrentUserPasswordRequest,
	props<ActionRequestPayload<number>>()
);

export const changeCurrentUserPasswordRequestSent = createAction(
	AccountActions.CurrentUserPasswordReseted
);

//for admin pwd reset
export const sendUserChangePasswordRequest = createAction(
	AccountActions.ResetUserPasswordRequest,
	props<ActionRequestPayload<number>>()
);

export const changeUserPasswordRequestSent = createAction(
	AccountActions.UserPasswordReseted
);

//Subscribe to group
export const subscribeToGroups = createAction(
	AccountActions.SubscribeToGroups,
	props<ActionRequestPayload<NotificationSubscriptionModel[]>>()
);

//unsubscribe from group
export const unSubscribeFromGroups = createAction(
	AccountActions.UnsubscribeFromGroups,
	props<ActionRequestPayload<NotificationSubscriptionModel[]>>()
);

export const errorOccurred = createAction(AccountActions.ErrorOccurred);
