import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ApmService } from '@elastic/apm-rum-angular';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IdentityProviders } from 'app/common/identity-providers';
import { isEmptyOrWhiteSpace } from 'app/common/utils/utils.string';
import {
	AccountActions,
	refreshToken
} from 'app/store/account/account.actions';
import { selectAccountStatus } from 'app/store/account/account.selectors';
import { AuthStatus, IAccountState } from 'app/store/account/account.state';
import {
	appRedirect,
	authenticated,
	errorOccurred,
	idTokenUpdated,
	loaded,
	loading
} from 'app/store/auth0/auth0.actions';
import { IAuth0State } from 'app/store/auth0/auth0.state';
import { environment } from 'environments/environment';
import {
	combineLatest,
	filter,
	mergeMap,
	Observable,
	of,
	skipWhile,
	take,
	tap
} from 'rxjs';

@Injectable()
export class AppInitService {
	private readonly auth0Service?: AuthService;
	private readonly auth0Store?: Store<IAuth0State>;

	constructor(
		private readonly accountStore: Store<IAccountState>,
		private readonly actions: Actions,
		apmService: ApmService
	) {
		if (environment.identityProvider === IdentityProviders.Auth0) {
			this.auth0Service = inject(AuthService);
			this.auth0Store = inject(Store<IAuth0State>);
		}

		if (environment.production) {
			const apm = apmService.init({
				serverUrl:        environment.apmServerUrl,
				serviceName:      environment.appName,
				serviceVersion:   environment.version.toString(),
				breakdownMetrics: true
			});

			apm.setUserContext({
				username: 'twrx-web',
				id:       'twrx-web-id'
			});
		}
	}

	initApplication(): Observable<unknown> {
		return environment.identityProvider === IdentityProviders.Auth0
			? this.initAuth0State()
			: this.initLegacyAuthState();
	}

	private initAuth0State(): Observable<unknown> {
		this.auth0Service.error$.subscribe(error =>
			this.auth0Store.dispatch(errorOccurred({ error }))
		);

		this.auth0Service.appState$
			.pipe(filter(({ target }) => isEmptyOrWhiteSpace(target)))
			.subscribe(() => this.auth0Store.dispatch(appRedirect()));

		return combineLatest([
			this.auth0Service.isLoading$.pipe(
				tap(isLoading =>
					isLoading
						? this.auth0Store.dispatch(loading())
						: this.auth0Store.dispatch(loaded())
				)
			),

			this.auth0Service.isAuthenticated$.pipe(
				tap(isAuthenticated =>
					isAuthenticated
						? this.auth0Store.dispatch(authenticated())
						: void 0
				)
			),

			this.auth0Service.idTokenClaims$.pipe(
				tap(idToken =>
					this.auth0Store.dispatch(idTokenUpdated(idToken))
				)
			)
		]).pipe(
			skipWhile(([isLoading]) => isLoading),
			take(1)
		);
	}

	private initLegacyAuthState(): Observable<unknown> {
		return this.accountStore.select(selectAccountStatus).pipe(
			take(1),
			mergeMap(s => {
				if (+s === AuthStatus.Authenticated) {
					this.accountStore.dispatch(refreshToken());

					return this.actions.pipe(
						filter(
							x =>
								x.type
									=== AccountActions.RefreshTokenSucceeded
								|| x.type === AccountActions.RefreshTokenFailed
						),
						take(1)
					);
				} else {
					return of();
				}
			})
		);
	}
}
