import { IdentityProviders } from 'app/common/identity-providers';
import * as packageJson from 'packageJson';

export const environment = {
	name:                     process.env.ENV_NAME,
	appName:                  process.env.APP_NAME,
	production:               true, // Used in decorator, must be defined
	apiUrl:                   '/api',
	identityProvider:         IdentityProviders.Auth0, // Used in decorator, must be defined
	apmServerUrl:             process.env.APM_SERVER_URL,
	useMl:                    process.env.USE_ML,
	version:                  packageJson?.version || -1,
	releaseVersion:           process.env.RELEASE_VERSION,
	idleTimeoutInSeconds:     parseInt(process.env.IDLE_TIMEOUT_IN_SECONDS, 10),
	tokenExpirationInSeconds: parseInt(
		process.env.TOKEN_EXPIRATION_IN_SECONDS,
		10
	),
	auth0: {
		domain:              process.env.AUTH0_DOMAIN,
		clientId:            process.env.AUTH0_CLIENT_ID,
		authorizationParams: {
			audience:     process.env.AUTH0_AUDIENCE,
			redirect_uri: process.env.AUTH0_CALLBACK
		},
		errorPath:       '/authenticate-error',
		httpInterceptor: {
			allowedList: ['/api/*']
		}
	},
	sentryDsn:           process.env.SENTRY_DSN,
	applicationInsights: {
		connectionString: process.env.APPLICATION_INSIGHTS_CONNNECTION_STRING,
		roleName:         process.env.APPLICATION_INSIGHTS_ROLE_NAME,
		roleInstance:     process.env.APPLICATION_INSIGHTS_ROLE_INSTANCE
	},
	signalrHubServiceUrl: process.env.SIGNALR_SERVICE
};
