<!-- User menu button -->
<button
	mat-icon-button
	[matMenuTriggerFor]="userActions"
	[matTooltip]="'profile.tooltip' | transloco"
	[matTooltipShowDelay]="listItemTooltipShowDelay"
>
	<mat-icon
		fontSet="fas"
		fontIcon="fa-user"
		class="relative text-xl"
	></mat-icon>
</button>

<!-- User menu -->
<mat-menu
	xPosition="before"
	#userActions="matMenu"
	class="min-w-64 rounded-xl border shadow-2xl"
>
	<span class="flex flex-col px-4 pt-4 leading-none">
		<span class="text-secondary">Signed in as</span>
		<span class="overflow-x-auto whitespace-nowrap pb-1 pt-2 text-lg font-medium">{{ userEmail | async }}</span>
	</span>
	<mat-divider class="my-1"></mat-divider>
	<button
		mat-menu-item
		[routerLink]="accountRoutes.Profile"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-user"
			class="fa-lg"
		></mat-icon>
		<span>{{ 'nav.profile' | transloco }}</span>
	</button>
	<mat-divider class="my-1"></mat-divider>
	<button
		class="mb-1"
		mat-menu-item
		(click)="signOut()"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-sign-out-alt"
			class="fa-lg"
		></mat-icon>
		<span>{{ 'nav.signOut' | transloco }}</span>
	</button>
</mat-menu>
