import { RebateInvalidRecordsResolveModel } from 'app/models/api/rebate-invalid-records-resolve-model';
import { createAction, props } from '@ngrx/store';
import { ApplyCoefficientResultModel } from 'app/models/api/apply-coefficient-result-model';
import { ApplyConversionCoefficientModel } from 'app/models/api/apply-conversion-coefficient-model';
import { CheckResultModel } from 'app/models/api/check-result-model';
import { ConversionHistoryModel } from 'app/models/api/conversion-history-model';
import { DeleteFileInvalidRecordsByErrorCodeModel } from 'app/models/api/delete-file-invalid-records-by-error-code-model';
import { ExecuteAggregationModel } from 'app/models/api/execute-aggregation-model';
import { ExecuteConversionModel } from 'app/models/api/execute-conversion-model';
import { ExecutePharmacyIdReviewActionModel } from 'app/models/api/execute-pharmacy-id-review-action-model';
import { FileDuplicateRebateRecordModel } from 'app/models/api/file-duplicate-rebate-record-model';
import { FilterBulkUpdateRequest } from 'app/models/api/filter-bulk-update-request';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { InvalidRebateRecordSearchModel } from 'app/models/api/invalid-rebate-record-search-model';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { MappingPreviewFilterModel } from 'app/models/api/mapping-preview-filter-model';
import { MarkFilteredAsDeletedModel } from 'app/models/api/mark-filtered-as-deleted-model';
import { NdcJCodeExclusionModel } from 'app/models/api/ndc-j-code-exclusion-model';
import { ProcessRebateLoadFileModel } from 'app/models/api/process-rebate-load-file-model';
import { ReadRebateLoadFileModel } from 'app/models/api/read-rebate-load-file-model';
import { RebateInvalidRecordsResolveAllModel } from 'app/models/api/rebate-invalid-records-resolve-all-model';
import { RebateLoadAggregationModel } from 'app/models/api/rebate-load-aggregation-model';
import { RebateLoadBaseSearchFilterModel } from 'app/models/api/rebate-load-base-search-filter-model';
import { RebateLoadConversionModel } from 'app/models/api/rebate-load-conversion-model';
import { RebateLoadConversionRecommendationModel } from 'app/models/api/rebate-load-conversion-recommendation-model';
import { RebateLoadConvertedModel } from 'app/models/api/rebate-load-converted-model';
import { RebateLoadFileDetailedListOptionModel } from 'app/models/api/rebate-load-file-detailed-list-option-model';
import { RebateLoadFilePeriod } from 'app/models/api/rebate-load-file-period';
import { RebateLoadFileSheetModel } from 'app/models/api/rebate-load-file-sheet-model';
import { RebateLoadFileStatus } from 'app/models/api/rebate-load-file-status';
import { RebateLoadFilesDetailedOptionsRequest } from 'app/models/api/rebate-load-files-detailed-options-request';
import { RebateLoadFilesOptionsRequest } from 'app/models/api/rebate-load-files-options-request';
import { RebateLoadLocationsSearchFilterModel } from 'app/models/api/rebate-load-locations-search-filter-model';
import { RebateRecordDuplicatePairModel } from 'app/models/api/rebate-record-duplicate-pair-model';
import { RebateRecordGroupedErrorsModel } from 'app/models/api/rebate-record-grouped-errors-model';
import { RebatesInvalidRecordsUpdateModel } from 'app/models/api/rebates-invalid-records-update-model';
import { RebatesLoadAggregationOptionsGroupModel } from 'app/models/api/rebates-load-aggregation-options-group-model';
import { RebatesLoadConversionOptionsGroupModel } from 'app/models/api/rebates-load-conversion-options-group-model';
import { RebatesLoadFileDetailsModel } from 'app/models/api/rebates-load-file-details-model';
import { RebatesLoadFileSearchFilterModel } from 'app/models/api/rebates-load-file-search-filter-model';
import { RebatesLoadInvalidRecordsBulkFilterModel } from 'app/models/api/rebates-load-invalid-records-bulk-filter-model';
import { RebatesLoadInvalidRecordsFilterModel } from 'app/models/api/rebates-load-invalid-records-filter-model';
import { RebatesLoadInvalidRecordsFiltersFilterModel } from 'app/models/api/rebates-load-invalid-records-filters-filter-model';
import { RebatesLoadMappingModel } from 'app/models/api/rebates-load-mapping-model';
import { RebatesLoadMappingRecommendationModel } from 'app/models/api/rebates-load-mapping-recommendation-model';
import { RebatesLoadStatistic } from 'app/models/api/rebates-load-statistic';
import { RebatesLoadStatisticRequest } from 'app/models/api/rebates-load-statistic-request';
import { RebatesLoadTemplateSaveModel } from 'app/models/api/rebates-load-template-save-model';
import { RecoveryJCodesModel } from 'app/models/api/recovery-j-codes-model';
import { RecoveryNdcJCodeCoefficientsModel } from 'app/models/api/recovery-ndc-j-code-coefficients-model';
import { ResolveAberrantQuantityRecordsModel } from 'app/models/api/resolve-aberrant-quantity-records-model';
import { ResolveAllDuplicateModel } from 'app/models/api/resolve-all-duplicate-model';
import { ResolveAllFileDuplicateModel } from 'app/models/api/resolve-all-file-duplicate-model';
import { ResolveDuplicateModel } from 'app/models/api/resolve-duplicate-model';
import { ResolveFileDuplicateModel } from 'app/models/api/resolve-file-duplicate-model';
import { SearchResponse } from 'app/models/api/search-response';
import { SetConversionQuantityModel } from 'app/models/api/set-conversion-quantity-model';
import { UnknownJCodeDetailsModel } from 'app/models/api/unknown-j-code-details-model';
import { UnmappedRebateLoadLocationModel } from 'app/models/api/unmapped-rebate-load-location-model';
import { UpdateMappingModel } from 'app/models/api/update-mapping-model';
import { UpdateNdcJCodeExclusionsModel } from 'app/models/api/update-ndc-j-code-exclusions-model';
import { NotConvertedRebateLoadTableModel } from 'app/models/dto/not-converted-rebate-load-table-model';
import { RebatesUploadModel } from 'app/models/dto/rebates-upload-model';
import { RebatesLoadFileSearchStoreModel } from 'app/models/dto/rebates-load-file-search-store-model';
import { MarkFilterResultModel } from 'app/models/api/mark-filter-result-model';
import { RecordsValidationResultModel } from 'app/models/api/records-validation-result-model';
import { ReviewedPharmacyIdItemModel } from 'app/models/api/reviewed-pharmacy-id-item-model';
import { NotReviewedPharmacyIdItemModel } from 'app/models/api/not-reviewed-pharmacy-id-item-model';
import { ChangeSubmissionPeriodRequest } from 'app/models/api/change-submission-period-request';
import { ResolveFilteredAberrantQuantityRecordsModel } from 'app/models/api/resolve-filtered-aberrant-quantity-records-model';
import { RebateLoadConvertedSearchFilterModel } from 'app/models/api/rebate-load-converted-search-filter-model';
import { RebateLoadStatusChangedNotification } from 'app/models/api/rebate-load-status-changed-notification';
import { RebateLoadFileCreatedNotification } from 'app/models/api/rebate-load-file-created-notification';
import { RebateLoadStatisticUpdatedNotification } from 'app/models/api/rebate-load-statistic-updated-notification';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebatesLoadActions {
	LoadRebatesLoadFiles = '[RebateLoad] LoadRebatesLoadFiles',
	RebatesLoadFilesLoaded = '[RebateLoad] RebatesLoadFilesLoaded',

	LoadRebatesLoadFilesStatuses = '[RebateLoad] LoadRebatesLoadFilesStatuses',
	RebatesLoadFilesStatusesLoaded = '[RebateLoad] RebatesLoadFilesStatusesLoaded',

	LoadRebatesLoadFilesStatus = '[RebateLoad] LoadRebatesLoadFilesStatus',
	RebatesLoadFilesStatusLoaded = '[RebateLoad] RebatesLoadFilesStatusLoaded',

	LoadConvertedRebateLoad = '[RebateLoad] LoadConvertedRebateLoad',
	ConvertedRebateLoadLoaded = '[RebateLoad] ConvertedRebateLoadLoaded',

	LoadNotConvertedRebateLoad = '[RebateLoad] LoadNotConvertedRebateLoad',
	NotConvertedRebateLoadLoaded = '[RebateLoad] NotConvertedRebateLoadLoaded',

	LoadRebateLoadConversions = '[RebateLoad] LoadRebateLoadConversions',
	RebateLoadConversionsLoaded = '[RebateLoad] RebateLoadConversionsLoaded',

	ResetRecommendedConversion = '[RebateLoad] ResetRecommendedConversion',

	LoadRebateLoadAggregations = '[RebateLoad] LoadRebateLoadAggregations',
	RebateLoadAggregationsLoaded = '[RebateLoad] RebateLoadAggregationsLoaded',

	ExecuteRebateLoadAggregation = '[RebateLoad] ExecuteRebateLoadAggregation',
	RebateLoadAggregated = '[RebateLoad] RebateLoadAggregated',

	ResolveDuplicates = '[RebateLoad] ResolveDuplicates',
	DuplicatesResolved = '[RebateLoad] DuplicatesResolved',

	ResolveAllDuplicates = '[RebateLoad] ResolveAllDuplicates',
	AllDuplicatesResolved = '[RebateLoad] AllDuplicatesResolved',

	ResolveFileDuplicates = '[RebateLoad] ResolveFileDuplicates',
	FileDuplicatesResolved = '[RebateLoad] FileDuplicatesResolved',

	ResolveAllFileDuplicates = '[RebateLoad] ResolveAllFileDuplicates',
	AllFileDuplicatesResolved = '[RebateLoad] AllFileDuplicatesResolved',

	ExecuteRebateLoadConversion = '[RebateLoad] ExecuteRebateLoadConversion',
	ExecuteCurrentRebateLoadConversion = '[RebateLoad] ExecuteCurrentRebateLoadConversion',
	RebateLoadConversionExecuted = '[RebateLoad] RebateLoadConversionExecuted',

	SetRebateLoadQuantities = '[RebateLoad] SetRebateLoadQuantities',
	RebateLoadQuantitiesSetted = '[RebateLoad] RebateLoadQuantitiesSetted',

	ExecuteProcessRebateLoadFile = '[RebateLoad] ExecuteProcessRebateLoadFile',
	ProcessRebateLoadFileExecuted = '[RebateLoad] ProcessRebateLoadFileExecuted',

	ResetRebateLoadConversionType = '[RebateLoad] ResetRebateLoadConversionType',
	RebateLoadConversionTypeReseted = '[RebateLoad] RebateLoadConversionTypeReseted',

	LoadRebatesLoadFileStatistic = '[RebateLoad] LoadRebatesLoadFileStatistic',
	RebatesLoadFileStatisticNotFound = '[RebateLoad] RebatesLoadFileStatisticNotFound',
	RebatesLoadFileStatisticLoaded = '[RebateLoad] RebatesLoadFileStatisticLoaded',

	LoadRebateRecordDuplicates = '[RebateLoad] LoadRebateRecordDuplicates',
	RebateRecordDuplicatesLoaded = '[RebateLoad] RebateRecordDuplicatesLoaded',

	LoadRebateRecordFileDuplicates = '[RebateLoad] LoadRebateRecordFileDuplicates',
	ClearRebateRecordFileDuplicates = '[RebateLoad] ClearRebateRecordFileDuplicates',
	RebateRecordFileDuplicatesLoaded = '[RebateLoad] RebateRecordFileDuplicatesLoaded',

	LoadRebatesLoadPeriodStatistic = '[RebateLoad] LoadRebatesLoadPeriodStatistic',
	RebatesLoadPeriodStatisticLoaded = '[RebateLoad] RebatesLoadPeriodStatisticLoaded',

	PreloadRebatesLoadFileDetails = '[RebateLoad] PreloadRebatesLoadFileDetails',
	PreloadedRebatesLoadFileNotFound = '[RebateLoad] PreloadedRebatesLoadFileNotFound',
	RebatesLoadFileDetailsPreloaded = '[RebateLoad] RebatesLoadFileDetailsPreloaded',

	LoadRebatesLoadFileDetails = '[RebateLoad] LoadRebatesLoadFileDetails',
	LoadedRebatesLoadFileNotFound = '[RebateLoad] LoadedRebatesLoadFileNotFound',
	RebatesLoadFileDetailsLoaded = '[RebateLoad] RebatesLoadFileDetailsLoaded',
	ClearRebatesLoadFileDetails = '[RebatesLoad] ClearRebatesLoadFileDetails',

	DeleteRebateLoadFile = '[RebateLoad] DeleteRebateLoadFile',
	RebatesLoadFileDeleted = '[RebateLoad] RebatesLoadFileDeleted',

	AddRebatesLoadFile = '[RebateLoad] AddRebatesLoadFile',
	RebatesLoadFileLoading = '[RebateLoad] RebatesLoadFileLoading',
	RebatesLoadFileAdded = '[RebateLoad] RebatesLoadFileAdded',

	LoadRebatesLoadTemplates = '[RebateLoad] LoadRebatesLoadTemplates',
	RebatesLoadTemplatesLoaded = '[RebateLoad] RebatesLoadTemplatesLoaded',

	LoadRebatesLoadYears = '[RebateLoad] LoadRebatesLoadYears',
	RebatesLoadYearsLoaded = '[RebateLoad] RebatesLoadYearsLoaded',

	LoadRebateLoadFilesOptions = '[RebateLoad] LoadRebateLoadFilesOptions',
	RebateLoadFilesOptionsLoaded = '[RebateLoad] RebateLoadFilesOptionsLoaded',

	LoadRebateLoadFilesDetailedOptions = '[RebateLoad] LoadRebateLoadFilesDetailedOptions',
	RebateLoadFilesDetailedOptionsLoaded = '[RebateLoad] RebateLoadFilesDetailedOptionsLoaded',

	LoadRebatesLoadTemplateMapping = '[RebateLoad] LoadRebatesLoadTemplateMapping',
	RebatesLoadTemplateMappingLoaded = '[RebateLoad] RebatesLoadTemplateMappingLoaded',

	SaveRebatesLoadTemplate = '[RebateLoad] SaveRebatesLoadTemplate',
	RebatesLoadTemplateSaved = '[RebateLoad] RebatesLoadTemplateSaved',

	DeleteRebatesLoadTemplate = '[RebateLoad] DeleteRebatesLoadTemplate',
	RebatesLoadTemplateDeleted = '[RebateLoad] RebatesLoadTemplateDeleted',

	ProcessRebatesLoadFile = '[RebateLoad] ProcessRebatesLoadFile',
	RebatesLoadFileProcessed = '[RebateLoad] RebatesLoadFileProcessed',

	LoadRebatesInvalidRecords = '[RebateLoad] LoadRebatesInvalidRecords',
	RebatesInvalidRecordsLoaded = '[RebateLoad] RebatesInvalidRecordsLoaded',

	ExportRebatesInvalidRecords = '[RebateLoad] ExportRebatesInvalidRecords',
	RebatesInvalidRecordsExported = '[RebateLoad] RebatesInvalidRecordsExported',

	LoadRebatesGroupedErrors = '[RebateLoad] LoadRebatesGroupedErrors',
	RebatesGroupedErrorsLoaded = '[RebateLoad] RebatesGroupedErrorsLoaded',
	RebatesGroupedWarningsLoaded = '[RebateLoad] RebatesGroupedWarningsLoaded',

	ResolveRebateLoadFileInvalidRecords = '[RebateLoad] ResolveRebateLoadFileInvalidRecords',
	RebatesLoadFileInvalidRecordsResolved = '[RebateLoad] RebatesLoadFileInvalidRecordsResolved',

	DeleteRebateLoadFileInvalidRecordsByErrorCode = '[RebateLoad] DeleteRebateLoadFileInvalidRecordsByErrorCode',
	RebatesLoadFileInvalidRecordsByErrorCodeDeleted = '[RebateLoad] RebatesLoadFileInvalidRecordsByErrorCodeDeleted',

	ResolveAllRebateLoadFileInvalidRecords = '[RebateLoad] ResolveAllRebateLoadFileInvalidRecords',
	AllRebatesLoadFileInvalidRecordsResolved = '[RebateLoad] AllRebatesLoadFileInvalidRecordsResolved',

	StartBulkUpdateInvalidRecords = '[RebateLoad] StartBulkUpdateInvalidRecords',
	InvalidRecordsBulkUpdateStarted = '[RebateLoad] InvalidRecordsBulkUpdateStarted',

	StartRebatesLoadFileReading = '[RebateLoad] StartRebatesLoadFileReading',
	RebatesLoadFileReadingStarted = '[RebateLoad] RebatesLoadFileReadingStarted',

	StartUpdatedRebatesRecordsValidation = '[RebateLoad] StartUpdatedRebatesRecordsValidation',
	UpdatedRebatesRecordsValidationStarted = '[RebateLoad] UpdatedRebatesRecordsValidationStarted',
	ValidateRebateLoadFile = '[RebateLoad] ValidateRebateLoadFile',
	RebateLoadFileValidated = '[RebateLoad] RebateLoadFileValidated',

	ApplyRebateLoadCoefficient = '[RebateLoad] ApplyRebateLoadCoefficient',
	RebateLoadCoefficientApplied = '[RebateLoad] RebateLoadCoefficientApplied',

	LoadRebatesLoadRecentAggregationOptions = '[RebateLoad] LoadRebatesLoadRecentAggregationOptions',
	RebatesLoadRecentAggregationOptionsLoaded = '[RebateLoad] RebatesLoadRecentAggregationOptionsLoaded',

	LoadRebatesLoadRecentConversionOptions = '[RebateLoad] LoadRebatesLoadRecentConversionOptions',
	RebatesLoadRecentConversionOptionsLoaded = '[RebateLoad] RebatesLoadRecentConversionOptionsLoaded',

	LoadRebateLoadFilePreviousPeriod = '[RebateLoad] LoadRebateLoadFilePreviousPeriod',
	RebateLoadFilePreviousPeriodLoaded = '[RebateLoad] RebateLoadFilePreviousPeriodLoaded',

	LoadRebateLoadLocations = '[RebateLoad] LoadRebateLoadLocations',
	NotReviewedLocationLoaded = '[RebateLoad] NotReviewedLocationLoaded',
	ReviewedLocationsLoaded = '[RebateLoad] ReviewedLocationsLoaded',

	UpdateRebateLoadMapping = '[RebateLoad] UpdateRebateLoadMapping',
	RebateLoadMappingUpdated = '[RebateLoad] RebateLoadMappingUpdated',

	SetTableFilters = '[RebateLoad] SetTableFilters',
	TableFiltersSet = '[RebateLoad] TableFiltersSet',

	LoadUnknownJCodeDetails = '[RebateLoad] LoadUnknownJCodeDetails',
	UnknownJCodeDetailsLoaded = '[RebateLoad] UnknownJCodeDetailsLoaded',

	RecoveryJCodes = '[RebateLoad] RecoveryJCodes',
	JCodesRecovered = '[RebateLoad] JCodesRecovered',

	RecoveryNdcJCodeCoefficients = '[RebateLoad] RecoveryNdcJCodeCoefficients',
	NdcJCodeCoefficientsRecovered = '[RebateLoad] NdcJCodeCoefficientsRecovered',

	LoadNdcJCodeExclusions = '[RebateLoad] LoadNdcJCodeExclusions',
	NdcJCodeExclusionsLoaded = '[RebateLoad] NdcJCodeExclusionsLoaded',

	UpdateNdcJCodeExclusions = '[RebateLoad] UpdateNdcJCodeExclusions',
	NdcJCodeExclusionsUpdated = '[RebateLoad] NdcJCodeExclusionsUpdated',

	LoadConversionHistory = '[RebateLoad] LoadConversionHistory',
	ConversionHistoryLoaded = '[RebateLoad] ConversionHistoryLoaded',

	ExportFileDuplicate = '[RebateLoad] ExportFileDuplicate',
	FileDuplicateExported = '[RebateLoad] FileDuplicateExported',

	ExportDuplicate = '[RebateLoad] ExportDuplicate',
	DuplicateExported = '[RebateLoad] DuplicateExported',

	RevalidateRebateLoadFileRecords = '[RebateLoad] RevalidateRebateLoadFileRecords',
	RebateLoadFileRecordsRevalidated = '[RebateLoad] RebateLoadFileRecordsRevalidated',

	LoadFileMappingPreview = '[RebateLoad] LoadFileMappingPreview',
	FileMappingPreviewLoaded = '[RebateLoad] FileMappingPreviewLoaded',

	ClearRebateLoadIndicator = '[RebateLoad] ClearRebateLoadIndicator',

	MarkFilteredRecordsAsDeleted = '[RebateLoad] MarkFilteredRecordsAsDeleted',
	FilteredRecordsMarkedAsDeleted = '[RebateLoad] FilteredRecordsMarkedAsDeleted',

	LoadAberrantQuantityRecordsTotalCount = '[RebateLoad] LoadAberrantQuantityRecordsTotalCount',
	AberrantQuantityRecordsTotalCountLoaded = '[RebateLoad] AberrantQuantityRecordsTotalCountLoaded',

	ResolveAberrantQuantityRecords = '[RebateLoad] ResolveAberrantQuantityRecords',
	AberrantQuantityRecordsResolved = '[RebateLoad] AberrantQuantityRecordsResolved',

	ResolveFilteredAberrantQuantityRecords = '[RebateLoad] ResolveFilteredAberrantQuantityRecords',
	FilteredAberrantQuantityRecordsResolved = '[RebateLoad] FilteredAberrantQuantityRecordsResolved',

	LoadCurrentFileMapping = '[RebatesLoad] LoadCurrentFileMapping',
	CurrentFileMappingLoaded = '[RebatesLoad] CurrentFileMappingLoaded',

	LoadFileSheets = '[RebatesLoad] LoadFileSheets',
	FileSheetsLoaded = '[RebatesLoad] FileSheets',

	CheckFileLocationsValid = '[RebatesLoad] CheckFileLocationsValid',
	FileLocationsValidChecked = '[RebatesLoad] FileLocationsValidChecked',

	CheckFileDefinitionsResolved = '[RebatesLoad] CheckFileDefinitionsResolved',
	FileDefinitionsResolvedChecked = '[RebatesLoad] FileDefinitionsResolvedChecked',

	LoadColumnFilters = '[RebatesLoad] LoadColumnFilters',
	ResetColumnFilters = '[RebatesLoad] ResetColumnFilters',
	ColumnFiltersLoaded = '[RebatesLoad] ColumnFiltersLoad',

	LoadRebateLoadConversionRecommendation = '[RebatesLoad] LoadRebateLoadConversionRecommendation',
	RebateLoadConversionRecommendationLoaded = '[RebatesLoad] rebateLoadConversionRecommendationLoaded',

	LoadRebateLoadMappingRecommendation = '[RebatesLoad] LoadRebateLoadMappingRecommendation',
	RebateLoadMappingRecommendationLoaded = '[RebatesLoad] RebateLoadMappingRecommendationLoaded',

	LoadNotReviewedPharmacyIdItems = '[RebatesLoad] LoadNotReviewedPharmacyIdItems',
	NotReviewedPharmacyIdReviewItemsLoaded = '[RebatesLoad] NotReviewedPharmacyIdReviewItemsLoaded',

	LoadReviewedPharmacyIdItems = '[RebatesLoad] LoadReviewedPharmacyIdItems',
	ReviewedPharmacyIdReviewItemsLoaded = '[RebatesLoad] ReviewedPharmacyIdReviewItemsLoaded',

	ExecutePharmacyIdReviewAction = '[RebatesLoad] ExecutePharmacyIdReviewAction',
	PharmacyIdReviewActionExecuted = '[RebatesLoad] PharmacyIdReviewActionExecuted',

	ChangeSubmissionPeriod = '[RebateLoad] ChangeSubmissionPeriod',
	SubmissionPeriodChanged = '[RebateLoad] SubmissionPeriodChanged',

	RebateLoadStatusUpdatedEvent = '[RebateLoad] [Event] RebateLoadStatusUpdatedEvent',
	RebateLoadFileCreatedEvent = '[RebateLoad] [Event] RebateLoadFileCreatedEvent',
	RebateLoadStatisticUpdatedEvent = '[RebateLoad] [Event] RebateLoadStatisticUpdatedEvent',

	ErrorOccurred = '[RebatesLoad] ErrorOccurred'
}

export const loadRebatesLoadFiles = createAction(
	RebatesLoadActions.LoadRebatesLoadFiles,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadFileSearchFilterModel>
		>
	>()
);

export const rebatesLoadFilesLoaded = createAction(
	RebatesLoadActions.RebatesLoadFilesLoaded,
	props<
		ActionResponsePayload<SearchResponse<RebatesLoadFileSearchStoreModel>>
	>()
);

export const loadRebatesLoadFilesStatuses = createAction(
	RebatesLoadActions.LoadRebatesLoadFilesStatuses,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadFileSearchFilterModel>
		>
	>()
);

export const rebatesLoadFilesStatusesLoaded = createAction(
	RebatesLoadActions.RebatesLoadFilesStatusesLoaded,
	props<ActionResponsePayload<RebateLoadFileStatus[]>>()
);

export const loadRebatesLoadFilesStatus = createAction(
	RebatesLoadActions.LoadRebatesLoadFilesStatus,
	props<ActionRequestPayload<number>>()
);

export const rebatesLoadFilesStatusLoaded = createAction(
	RebatesLoadActions.RebatesLoadFilesStatusLoaded,
	props<ActionResponsePayload<RebateLoadFileStatus>>()
);

export const loadRebateLoadAggregations = createAction(
	RebatesLoadActions.LoadRebateLoadAggregations,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const rebateLoadAggregationsLoaded = createAction(
	RebatesLoadActions.RebateLoadAggregationsLoaded,
	props<ActionResponsePayload<SearchResponse<RebateLoadAggregationModel>>>()
);

export const loadRebateLoadDuplicates = createAction(
	RebatesLoadActions.LoadRebateRecordDuplicates,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const rebateLoadDuplicatesLoaded = createAction(
	RebatesLoadActions.RebateRecordDuplicatesLoaded,
	props<
		ActionResponsePayload<SearchResponse<RebateRecordDuplicatePairModel>>
	>()
);

export const loadRebateLoadFileDuplicates = createAction(
	RebatesLoadActions.LoadRebateRecordFileDuplicates,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const clearRebateLoadFileDuplicates = createAction(
	RebatesLoadActions.ClearRebateRecordFileDuplicates
);

export const rebateLoadFileDuplicatesLoaded = createAction(
	RebatesLoadActions.RebateRecordFileDuplicatesLoaded,
	props<
		ActionResponsePayload<SearchResponse<FileDuplicateRebateRecordModel>>
	>()
);

export const executeRebateLoadAggregation = createAction(
	RebatesLoadActions.ExecuteRebateLoadAggregation,
	props<ActionRequestPayload<ExecuteAggregationModel>>()
);

export const rebateLoadAggregationExecuted = createAction(
	RebatesLoadActions.RebateLoadAggregated
);

export const executeRebateLoadConversion = createAction(
	RebatesLoadActions.ExecuteRebateLoadConversion,
	props<ActionRequestPayload<ExecuteConversionModel>>()
);

export const executeCurrentRebateLoadConversion = createAction(
	RebatesLoadActions.ExecuteCurrentRebateLoadConversion,
	props<ActionRequestPayload<number>>()
);

export const rebateLoadConversionExecuted = createAction(
	RebatesLoadActions.RebateLoadConversionExecuted
);

export const resolveDuplicates = createAction(
	RebatesLoadActions.ResolveDuplicates,
	props<ActionRequestPayload<ResolveDuplicateModel>>()
);

export const duplicatesResolved = createAction(
	RebatesLoadActions.DuplicatesResolved,
	props<ActionResponsePayload<RecordsValidationResultModel>>()
);

export const resolveAllDuplicates = createAction(
	RebatesLoadActions.ResolveAllDuplicates,
	props<ActionRequestPayload<ResolveAllDuplicateModel>>()
);

export const allDuplicatesResolved = createAction(
	RebatesLoadActions.AllDuplicatesResolved
);

export const resolveFileDuplicates = createAction(
	RebatesLoadActions.ResolveFileDuplicates,
	props<ActionRequestPayload<ResolveFileDuplicateModel>>()
);

export const fileDuplicatesResolved = createAction(
	RebatesLoadActions.FileDuplicatesResolved
);

export const resolveAllFileDuplicates = createAction(
	RebatesLoadActions.ResolveAllFileDuplicates,
	props<ActionRequestPayload<ResolveAllFileDuplicateModel>>()
);

export const allFileDuplicatesResolved = createAction(
	RebatesLoadActions.AllFileDuplicatesResolved
);

export const applyRebateLoadCoefficient = createAction(
	RebatesLoadActions.ApplyRebateLoadCoefficient,
	props<ActionRequestPayload<ApplyConversionCoefficientModel>>()
);

export const rebateLoadCoefficientApplied = createAction(
	RebatesLoadActions.RebateLoadCoefficientApplied,
	props<ActionResponsePayload<ApplyCoefficientResultModel>>()
);

export const setRebateLoadQuantities = createAction(
	RebatesLoadActions.SetRebateLoadQuantities,
	props<ActionRequestPayload<SetConversionQuantityModel>>()
);

export const rebateLoadQuantitiesSetted = createAction(
	RebatesLoadActions.RebateLoadQuantitiesSetted,
	props<ActionResponsePayload<RecordsValidationResultModel>>()
);

export const resetRebateLoadConversionType = createAction(
	RebatesLoadActions.ResetRebateLoadConversionType,
	props<ActionRequestPayload<number>>()
);

export const rebateLoadConversionTypeReseted = createAction(
	RebatesLoadActions.RebateLoadConversionTypeReseted,
	props<ActionResponsePayload<number>>()
);

export const resetRecommendedConversion = createAction(
	RebatesLoadActions.ResetRecommendedConversion
);

export const loadRebatesLoadConversions = createAction(
	RebatesLoadActions.LoadRebateLoadConversions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const rebatesLoadConversionsLoaded = createAction(
	RebatesLoadActions.RebateLoadConversionsLoaded,
	props<ActionResponsePayload<SearchResponse<RebateLoadConversionModel>>>()
);

export const loadConvertedRebatesLoad = createAction(
	RebatesLoadActions.LoadConvertedRebateLoad,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadConvertedSearchFilterModel>
		>
	>()
);

export const convertedRebatesLoadLoaded = createAction(
	RebatesLoadActions.ConvertedRebateLoadLoaded,
	props<ActionResponsePayload<SearchResponse<RebateLoadConvertedModel>>>()
);

export const loadNotConvertedRebatesLoad = createAction(
	RebatesLoadActions.LoadNotConvertedRebateLoad,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const notConvertedRebatesLoadLoaded = createAction(
	RebatesLoadActions.NotConvertedRebateLoadLoaded,
	props<
		ActionResponsePayload<SearchResponse<NotConvertedRebateLoadTableModel>>
	>()
);

export const processRebateLoadFile = createAction(
	RebatesLoadActions.ExecuteProcessRebateLoadFile,
	props<ActionRequestPayload<ProcessRebateLoadFileModel>>()
);

export const processRebateLoadFileExecuted = createAction(
	RebatesLoadActions.ProcessRebateLoadFileExecuted
);

export const loadRebatesLoadFileStatistic = createAction(
	RebatesLoadActions.LoadRebatesLoadFileStatistic,
	props<ActionRequestPayload<number>>()
);

export const rebatesLoadFileStatisticNotFound = createAction(
	RebatesLoadActions.RebatesLoadFileStatisticNotFound
);

export const rebatesLoadFileStatisticLoaded = createAction(
	RebatesLoadActions.RebatesLoadFileStatisticLoaded,
	props<ActionResponsePayload<RebatesLoadStatistic>>()
);

export const loadRebatesLoadPeriodStatistic = createAction(
	RebatesLoadActions.LoadRebatesLoadPeriodStatistic,
	props<ActionRequestPayload<RebatesLoadStatisticRequest>>()
);

export const rebatesLoadPeriodStatisticLoaded = createAction(
	RebatesLoadActions.RebatesLoadPeriodStatisticLoaded,
	props<ActionResponsePayload<RebatesLoadStatistic>>()
);

export const preloadRebatesLoadFileDetails = createAction(
	RebatesLoadActions.PreloadRebatesLoadFileDetails,
	props<ActionRequestPayload<number>>()
);

export const preloadedRebatesLoadFileNotFound = createAction(
	RebatesLoadActions.PreloadedRebatesLoadFileNotFound
);

export const rebatesLoadFileDetailsPreloaded = createAction(
	RebatesLoadActions.RebatesLoadFileDetailsPreloaded,
	props<ActionResponsePayload<RebatesLoadFileDetailsModel>>()
);

export const loadRebatesLoadFileDetails = createAction(
	RebatesLoadActions.LoadRebatesLoadFileDetails,
	props<ActionRequestPayload<number>>()
);

export const loadedRebatesLoadFileNotFound = createAction(
	RebatesLoadActions.LoadedRebatesLoadFileNotFound
);

export const rebatesLoadFileDetailsLoaded = createAction(
	RebatesLoadActions.RebatesLoadFileDetailsLoaded,
	props<ActionResponsePayload<RebatesLoadFileDetailsModel>>()
);

export const clearRebatesLoadFileDetails = createAction(
	RebatesLoadActions.ClearRebatesLoadFileDetails
);

export const deleteRebateLoadFile = createAction(
	RebatesLoadActions.DeleteRebateLoadFile,
	props<ActionRequestPayload<number>>()
);

export const rebatesLoadFileDeleted = createAction(
	RebatesLoadActions.RebatesLoadFileDeleted,
	props<ActionRequestPayload<number>>()
);

export const addRebatesLoadFile = createAction(
	RebatesLoadActions.AddRebatesLoadFile,
	props<ActionRequestPayload<RebatesUploadModel>>()
);

export const rebatesLoadFileLoading = createAction(
	RebatesLoadActions.RebatesLoadFileLoading,
	props<ActionResponsePayload<number>>()
);

export const rebatesLoadFileAdded = createAction(
	RebatesLoadActions.RebatesLoadFileAdded,
	props<ActionResponsePayload<number>>()
);

export const loadRebatesLoadTemplates = createAction(
	RebatesLoadActions.LoadRebatesLoadTemplates,
	props<ActionResponsePayload<void>>()
);

export const rebatesLoadTemplatesLoaded = createAction(
	RebatesLoadActions.RebatesLoadTemplatesLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadRebatesLoadYears = createAction(
	RebatesLoadActions.LoadRebatesLoadYears,
	props<ActionResponsePayload<void>>()
);

export const rebatesLoadYearsLoaded = createAction(
	RebatesLoadActions.RebatesLoadYearsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadRebateLoadFileOptions = createAction(
	RebatesLoadActions.LoadRebateLoadFilesOptions,
	props<ActionResponsePayload<RebateLoadFilesOptionsRequest>>()
);

export const rebateLoadFileOptionsLoaded = createAction(
	RebatesLoadActions.RebateLoadFilesOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadRebateLoadFileDetailedOptions = createAction(
	RebatesLoadActions.LoadRebateLoadFilesDetailedOptions,
	props<ActionRequestPayload<RebateLoadFilesDetailedOptionsRequest>>()
);

export const rebateLoadFileDetailedOptionsLoaded = createAction(
	RebatesLoadActions.RebateLoadFilesDetailedOptionsLoaded,
	props<ActionResponsePayload<RebateLoadFileDetailedListOptionModel[]>>()
);

export const processRebatesLoadFile = createAction(
	RebatesLoadActions.ProcessRebatesLoadFile,
	props<ActionRequestPayload<ProcessRebateLoadFileModel>>()
);

export const rebatesLoadFileProcessed = createAction(
	RebatesLoadActions.RebatesLoadFileProcessed
);

export const loadRebatesLoadTemplateMapping = createAction(
	RebatesLoadActions.LoadRebatesLoadTemplateMapping,
	props<ActionRequestPayload<number>>()
);

export const rebatesLoadTemplateMappingLoaded = createAction(
	RebatesLoadActions.RebatesLoadTemplateMappingLoaded,
	props<ActionResponsePayload<RebatesLoadMappingModel>>()
);

export const saveRebatesLoadTemplate = createAction(
	RebatesLoadActions.SaveRebatesLoadTemplate,
	props<ActionRequestPayload<RebatesLoadTemplateSaveModel>>()
);

export const rebatesLoadTemplateSaved = createAction(
	RebatesLoadActions.RebatesLoadTemplateSaved,
	props<ActionResponsePayload<number>>()
);

export const deleteRebatesLoadTemplate = createAction(
	RebatesLoadActions.DeleteRebatesLoadTemplate,
	props<ActionRequestPayload<number>>()
);

export const rebatesLoadTemplateDeleted = createAction(
	RebatesLoadActions.RebatesLoadTemplateDeleted
);

export const loadRebatesInvalidRecords = createAction(
	RebatesLoadActions.LoadRebatesInvalidRecords,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadInvalidRecordsFilterModel>
		>
	>()
);

export const rebatesInvalidRecordsLoaded = createAction(
	RebatesLoadActions.RebatesInvalidRecordsLoaded,
	props<
		ActionResponsePayload<SearchResponse<InvalidRebateRecordSearchModel>>
	>()
);

export const exportRebatesInvalidRecords = createAction(
	RebatesLoadActions.ExportRebatesInvalidRecords,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadInvalidRecordsFilterModel>
		>
	>()
);

export const rebatesInvalidRecordsExported = createAction(
	RebatesLoadActions.RebatesInvalidRecordsExported
);

export const loadRebatesGroupedErrors = createAction(
	RebatesLoadActions.LoadRebatesGroupedErrors,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadInvalidRecordsFilterModel>
		>
	>()
);

export const rebatesGroupedErrorsLoaded = createAction(
	RebatesLoadActions.RebatesGroupedErrorsLoaded,
	props<
		ActionResponsePayload<SearchResponse<RebateRecordGroupedErrorsModel>>
	>()
);

export const rebatesGroupedWarningsLoaded = createAction(
	RebatesLoadActions.RebatesGroupedWarningsLoaded,
	props<
		ActionResponsePayload<SearchResponse<RebateRecordGroupedErrorsModel>>
	>()
);

export const resolveRebateLoadFileInvalidRecords = createAction(
	RebatesLoadActions.ResolveRebateLoadFileInvalidRecords,
	props<ActionRequestPayload<RebateInvalidRecordsResolveModel>>()
);

export const rebatesLoadFileInvalidRecordsResolved = createAction(
	RebatesLoadActions.RebatesLoadFileInvalidRecordsResolved
);

export const deleteRebateLoadFileInvalidRecordsByErrorCode = createAction(
	RebatesLoadActions.DeleteRebateLoadFileInvalidRecordsByErrorCode,
	props<ActionRequestPayload<DeleteFileInvalidRecordsByErrorCodeModel>>()
);

export const rebatesLoadFileInvalidRecordsByErrorCodeDeleted = createAction(
	RebatesLoadActions.RebatesLoadFileInvalidRecordsByErrorCodeDeleted
);

export const resolveAllRebateLoadFileInvalidRecords = createAction(
	RebatesLoadActions.ResolveAllRebateLoadFileInvalidRecords,
	props<ActionRequestPayload<RebateInvalidRecordsResolveAllModel>>()
);

export const allRebatesLoadFileInvalidRecordsResolved = createAction(
	RebatesLoadActions.AllRebatesLoadFileInvalidRecordsResolved
);

export const startRebatesLoadFileReading = createAction(
	RebatesLoadActions.StartRebatesLoadFileReading,
	props<ActionRequestPayload<ReadRebateLoadFileModel>>()
);

export const rebatesLoadFileReadingStarted = createAction(
	RebatesLoadActions.RebatesLoadFileReadingStarted,
	props<ActionResponsePayload<number>>()
);

export const startBulkUpdateInvalidRecords = createAction(
	RebatesLoadActions.StartBulkUpdateInvalidRecords,
	props<
		ActionRequestPayload<
			FilterBulkUpdateRequest<RebatesLoadInvalidRecordsBulkFilterModel>
		>
	>()
);

export const invalidRecordsBulkUpdateStarted = createAction(
	RebatesLoadActions.InvalidRecordsBulkUpdateStarted
);

export const startUpdatedRebatesRecordsValidation = createAction(
	RebatesLoadActions.StartUpdatedRebatesRecordsValidation,
	props<ActionRequestPayload<RebatesInvalidRecordsUpdateModel>>()
);

export const updatedRebatesRecordsValidationStarted = createAction(
	RebatesLoadActions.UpdatedRebatesRecordsValidationStarted,
	props<ActionResponsePayload<number>>()
);

export const loadRebatesLoadRecentAggregationOptions = createAction(
	RebatesLoadActions.LoadRebatesLoadRecentAggregationOptions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const rebatesLoadRecentAggregationOptionsLoaded = createAction(
	RebatesLoadActions.RebatesLoadRecentAggregationOptionsLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebatesLoadAggregationOptionsGroupModel>
		>
	>()
);

export const loadRebatesLoadRecentConversionOptions = createAction(
	RebatesLoadActions.LoadRebatesLoadRecentConversionOptions,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const rebatesLoadRecentConversionOptionsLoaded = createAction(
	RebatesLoadActions.RebatesLoadRecentConversionOptionsLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebatesLoadConversionOptionsGroupModel>
		>
	>()
);

export const loadRebateLoadFilePreviousPeriod = createAction(
	RebatesLoadActions.LoadRebateLoadFilePreviousPeriod,
	props<ActionResponsePayload<number>>()
);

export const rebateLoadFilePreviousPeriodLoaded = createAction(
	RebatesLoadActions.RebateLoadFilePreviousPeriodLoaded,
	props<ActionResponsePayload<RebateLoadFilePeriod>>()
);

export const loadRebateLoadLocations = createAction(
	RebatesLoadActions.LoadRebateLoadLocations,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadLocationsSearchFilterModel>
		>
	>()
);

export const reviewedLocationLoaded = createAction(
	RebatesLoadActions.ReviewedLocationsLoaded,
	props<
		ActionResponsePayload<SearchResponse<UnmappedRebateLoadLocationModel>>
	>()
);

export const notReviewedLocationLoaded = createAction(
	RebatesLoadActions.NotReviewedLocationLoaded,
	props<
		ActionResponsePayload<SearchResponse<UnmappedRebateLoadLocationModel>>
	>()
);

export const validateRebateLoadFile = createAction(
	RebatesLoadActions.ValidateRebateLoadFile,
	props<ActionRequestPayload<number>>()
);

export const rebateLoadFileValidated = createAction(
	RebatesLoadActions.RebateLoadFileValidated
);

export const updateRebateLoadMapping = createAction(
	RebatesLoadActions.UpdateRebateLoadMapping,
	props<ActionRequestPayload<UpdateMappingModel>>()
);

export const rebateLoadMapping = createAction(
	RebatesLoadActions.RebateLoadMappingUpdated
);

export const setTableFilters = createAction(
	RebatesLoadActions.SetTableFilters,
	props<ActionRequestPayload<RebatesLoadFileSearchFilterModel>>()
);

export const tableFiltersSet = createAction(
	RebatesLoadActions.TableFiltersSet,
	props<ActionResponsePayload<RebatesLoadFileSearchFilterModel>>()
);

export const loadConversionHistory = createAction(
	RebatesLoadActions.LoadConversionHistory,
	props<ActionRequestPayload<number>>()
);

export const conversionHistoryLoaded = createAction(
	RebatesLoadActions.ConversionHistoryLoaded,
	props<ActionResponsePayload<ConversionHistoryModel>>()
);

export const exportFileDuplicate = createAction(
	RebatesLoadActions.ExportFileDuplicate,
	props<ActionRequestPayload<number>>()
);

export const fileDuplicateExported = createAction(
	RebatesLoadActions.FileDuplicateExported
);

export const exportDuplicate = createAction(
	RebatesLoadActions.ExportDuplicate,
	props<ActionRequestPayload<number>>()
);

export const duplicateExported = createAction(
	RebatesLoadActions.DuplicateExported
);

export const revalidateRebateLoadFileRecords = createAction(
	RebatesLoadActions.RevalidateRebateLoadFileRecords,
	props<ActionRequestPayload<number>>()
);

export const loadUnknownJCodeDetails = createAction(
	RebatesLoadActions.LoadUnknownJCodeDetails,
	props<ActionRequestPayload<number>>()
);

export const unknownJCodeDetailsLoaded = createAction(
	RebatesLoadActions.UnknownJCodeDetailsLoaded,
	props<ActionResponsePayload<UnknownJCodeDetailsModel>>()
);

export const recoveryJCodes = createAction(
	RebatesLoadActions.RecoveryJCodes,
	props<ActionRequestPayload<RecoveryJCodesModel>>()
);

export const jCodesRecovered = createAction(
	RebatesLoadActions.JCodesRecovered,
	props<ActionRequestPayload<RecoveryJCodesModel>>()
);

export const recoveryNdcJCodeCoefficients = createAction(
	RebatesLoadActions.RecoveryNdcJCodeCoefficients,
	props<ActionRequestPayload<RecoveryNdcJCodeCoefficientsModel>>()
);

export const ndcJCodeCoefficientsRecovered = createAction(
	RebatesLoadActions.NdcJCodeCoefficientsRecovered,
	props<ActionRequestPayload<RecoveryNdcJCodeCoefficientsModel>>()
);

export const loadNdcJCodeExclusions = createAction(
	RebatesLoadActions.LoadNdcJCodeExclusions,
	props<ActionRequestPayload<number>>()
);

export const ndcJCodeExclusionsLoaded = createAction(
	RebatesLoadActions.NdcJCodeExclusionsLoaded,
	props<ActionResponsePayload<NdcJCodeExclusionModel[]>>()
);

export const updateNdcJCodeExclusions = createAction(
	RebatesLoadActions.UpdateNdcJCodeExclusions,
	props<ActionRequestPayload<UpdateNdcJCodeExclusionsModel>>()
);

export const ndcJCodeExclusionsUpdated = createAction(
	RebatesLoadActions.NdcJCodeExclusionsUpdated
);

export const rebateLoadFileRecordsRevalidated = createAction(
	RebatesLoadActions.RebateLoadFileRecordsRevalidated
);

export const loadFileMappingPreview = createAction(
	RebatesLoadActions.LoadFileMappingPreview,
	props<
		ActionRequestPayload<FilterSearchRequest<MappingPreviewFilterModel>>
	>()
);

export const fileMappingPreviewLoaded = createAction(
	RebatesLoadActions.FileMappingPreviewLoaded,
	props<ActionResponsePayload<SearchResponse<unknown>>>()
);

export const clearRebateLoadIndicator = createAction(
	RebatesLoadActions.ClearRebateLoadIndicator
);

export const markFilteredRecordsAsDeleted = createAction(
	RebatesLoadActions.MarkFilteredRecordsAsDeleted,
	props<ActionRequestPayload<MarkFilteredAsDeletedModel>>()
);

export const filteredRecordsMarkedAsDeleted = createAction(
	RebatesLoadActions.FilteredRecordsMarkedAsDeleted,
	props<ActionResponsePayload<MarkFilterResultModel>>()
);

export const loadAberrantQuantityRecordsTotalCount = createAction(
	RebatesLoadActions.LoadAberrantQuantityRecordsTotalCount,
	props<ActionRequestPayload<RebateLoadBaseSearchFilterModel>>()
);

export const aberrantQuantityRecordsTotalCountLoaded = createAction(
	RebatesLoadActions.AberrantQuantityRecordsTotalCountLoaded,
	props<ActionResponsePayload<number>>()
);

export const resolveAberrantQuantityRecords = createAction(
	RebatesLoadActions.ResolveAberrantQuantityRecords,
	props<ActionRequestPayload<ResolveAberrantQuantityRecordsModel>>()
);

export const aberrantQuantityRecordsResolved = createAction(
	RebatesLoadActions.AberrantQuantityRecordsResolved,
	props<ActionResponsePayload<RecordsValidationResultModel>>()
);

export const resolveFilteredAberrantQuantityRecords = createAction(
	RebatesLoadActions.ResolveFilteredAberrantQuantityRecords,
	props<ActionRequestPayload<ResolveFilteredAberrantQuantityRecordsModel>>()
);

export const filteredAberrantQuantityRecordsResolved = createAction(
	RebatesLoadActions.FilteredAberrantQuantityRecordsResolved,
	props<ActionResponsePayload<RecordsValidationResultModel>>()
);

export const loadCurrentFileMapping = createAction(
	RebatesLoadActions.LoadCurrentFileMapping,
	props<ActionRequestPayload<number>>()
);

export const currentFileMappingLoaded = createAction(
	RebatesLoadActions.CurrentFileMappingLoaded,
	props<ActionResponsePayload<RebatesLoadMappingModel>>()
);

export const loadFileSheets = createAction(
	RebatesLoadActions.LoadFileSheets,
	props<ActionRequestPayload<number>>()
);

export const fileSheetsLoaded = createAction(
	RebatesLoadActions.FileSheetsLoaded,
	props<ActionResponsePayload<RebateLoadFileSheetModel[]>>()
);

export const checkFileLocationsValid = createAction(
	RebatesLoadActions.CheckFileLocationsValid,
	props<ActionRequestPayload<number>>()
);

export const fileLocationsValidChecked = createAction(
	RebatesLoadActions.FileLocationsValidChecked,
	props<ActionResponsePayload<CheckResultModel>>()
);

export const checkFileDefinitionsResolved = createAction(
	RebatesLoadActions.CheckFileDefinitionsResolved,
	props<ActionRequestPayload<number>>()
);

export const fileDefinitionsResolvedChecked = createAction(
	RebatesLoadActions.FileDefinitionsResolvedChecked,
	props<ActionResponsePayload<CheckResultModel>>()
);

export const loadColumnFilters = createAction(
	RebatesLoadActions.LoadColumnFilters,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebatesLoadInvalidRecordsFiltersFilterModel>
		>
	>()
);

export const resetColumnFilters = createAction(
	RebatesLoadActions.ResetColumnFilters
);

export const columnFiltersLoaded = createAction(
	RebatesLoadActions.ColumnFiltersLoaded,
	props<ActionResponsePayload<SearchResponse<string>>>()
);

export const loadRebateLoadConversionRecommendation = createAction(
	RebatesLoadActions.LoadRebateLoadConversionRecommendation,
	props<ActionRequestPayload<number>>()
);

export const rebateLoadConversionRecommendationLoaded = createAction(
	RebatesLoadActions.RebateLoadConversionRecommendationLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebateLoadConversionRecommendationModel>
		>
	>()
);

export const loadRebateLoadMappingRecommendation = createAction(
	RebatesLoadActions.LoadRebateLoadMappingRecommendation,
	props<ActionRequestPayload<number>>()
);

export const rebateLoadMappingRecommendationLoaded = createAction(
	RebatesLoadActions.RebateLoadMappingRecommendationLoaded,
	props<ActionResponsePayload<RebatesLoadMappingRecommendationModel>>()
);

export const loadNotReviewedPharmacyIdItems = createAction(
	RebatesLoadActions.LoadNotReviewedPharmacyIdItems,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const notReviewedPharmacyIdReviewItemsLoaded = createAction(
	RebatesLoadActions.NotReviewedPharmacyIdReviewItemsLoaded,
	props<
		ActionResponsePayload<SearchResponse<NotReviewedPharmacyIdItemModel>>
	>()
);

export const loadReviewedPharmacyIdItems = createAction(
	RebatesLoadActions.LoadReviewedPharmacyIdItems,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateLoadBaseSearchFilterModel>
		>
	>()
);

export const reviewedPharmacyIdReviewItemsLoaded = createAction(
	RebatesLoadActions.ReviewedPharmacyIdReviewItemsLoaded,
	props<ActionResponsePayload<SearchResponse<ReviewedPharmacyIdItemModel>>>()
);

export const executePharmacyIdReviewAction = createAction(
	RebatesLoadActions.ExecutePharmacyIdReviewAction,
	props<ActionRequestPayload<ExecutePharmacyIdReviewActionModel>>()
);

export const pharmacyIdReviewActionExecuted = createAction(
	RebatesLoadActions.PharmacyIdReviewActionExecuted,
	props<ActionResponsePayload<number>>()
);

export const changeSubmissionPeriod = createAction(
	RebatesLoadActions.ChangeSubmissionPeriod,
	props<ActionRequestPayload<ChangeSubmissionPeriodRequest>>()
);

export const submissionPeriodChanged = createAction(
	RebatesLoadActions.SubmissionPeriodChanged
);

export const rebateLoadStatusUpdated = createAction(
	RebatesLoadActions.RebateLoadStatusUpdatedEvent,
	props<ActionResponsePayload<RebateLoadStatusChangedNotification>>()
);

export const rebateLoadFileCreated = createAction(
	RebatesLoadActions.RebateLoadFileCreatedEvent,
	props<ActionResponsePayload<RebateLoadFileCreatedNotification>>()
);

export const rebateLoadStatisticUpdated = createAction(
	RebatesLoadActions.RebateLoadStatisticUpdatedEvent,
	props<ActionResponsePayload<RebateLoadStatisticUpdatedNotification>>()
);

export const errorOccurred = createAction(RebatesLoadActions.ErrorOccurred);
